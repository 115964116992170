<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('CustMenu.Com.Action.ViewDownloadTenderClarifications')"
        :cards="cards"
        v-slot="{ card }"
      >
        <pro-deck-card
          :title="card.title"
          :date="card.date"
          :actions="card.actions"
          expandable
        >
          <pro-query-details
            :queryText="card.queryText"
            :attachments="card.attachments"
          />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProQueryDetails from "@/components/PROSmart/ProQueryDetails";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProQueryDetails },
  data() {
    return {
      cards: [],
    };
  },
  created() {
    Promise.all([
      this.$proSmart.tender.tendererGetTenderClarificationList(
        this,
        this.$route.params.id
      ),
      this.$proSmart.tender.getWorkflowCode(this, this.$route.params.id, [
        "tenderer_response_to_clarification",
      ]),
    ]).then(([queryList, workflowInfoList]) => {
      this.cards = queryList.map((query) => ({
        key: query.queryId,
        title: query.description,
        date: new Date(query.dateSubmitted),
        queryText: query.queryText,
        attachments: query.attachments,
        actions: [
          {
            label: this.getRes("System.Button.Reply"),
            to: {
              name: "DocumentViewQueryForm",
              params: {
                mode: "Edit",
                code: workflowInfoList[0].workflowCode,
                stepperId: this.$route.params.stepperId,
                queryId: query.queryId,
              },
            },
          },
        ],
      }));
    });
  },
};
</script>
