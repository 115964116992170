<template>
  <div>
    <!-- prettier-ignore -->
    <div class="q-ml-md" style="font-size: 14px; white-space: pre-wrap">{{ queryText }}</div>
    <div class="q-mt-md text-bold" style="font-size: 16px">{{ getRes('Form.Field.Attachments') }}</div>
    <div class="q-ml-md">
      <div v-for="attachment in attachments" :key="attachment.fileId">
        <pro-file :file="attachment" show-download-button />
      </div>
      <div v-if="attachments.length === 0">---</div>
    </div>
  </div>
</template>

<script>
import ProFile from "@/components/PROSmart/ProFile";

export default {
  name: "ProQueryDetails",
  components: { ProFile },
  props: {
    queryText: String,
    attachments: {
      type: Array,
      required: true,
    },
  },
};
</script>
